<template>
  <div class="supplier-balances">
    <div class="table-wrapper">
      <rcc-table
        v-if="headers.length > 0"
        :is-fixed-header="true"
        :page.sync="page"
        :page-count="pages"
        :items-per-page="50"
        :is-loading="isDataLoading"
        :headers="headers"
        :rows="rows"
        @filters-changed="handleFiltersChanged"
      >
        <template #body="{ rows: tableRows }">
          <tr
            v-for="(row, index) in tableRows"
            :key="index"
          >
            <td
              v-for="({ value }) in headers"
              :key="value"
              :class="getCellClasses(row)"
            >
              {{ row[value] }}
            </td>
          </tr>

          <tr class="total-row">
            <td>
              Итого:
            </td>
            <td>
              {{ summary.total }}
            </td>
            <td>
              {{ summary.updated_at }}
            </td>
          </tr>
        </template>
      </rcc-table>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import Page from '@/mixins/page'
import TableList from '@/mixins/table-list'

import RccTable from 'Components/table/rcc-table'

const HEADERS = [
  { text: 'Наименование РЦ', value: 'dc' },
  { text: 'Остаток по балансам', value: 'balance', sortable: true, sortDirection: 'desc' },
  { text: 'Дата и время обновления', value: 'updated_at' }
]

export default {
  name: 'RccBalancesForSupplierRole',

  components: {
    RccTable
  },

  mixins: [Page, TableList],

  data() {
    return {
      summary: {},
      rows: [],
      page: 1,
      pages: 1,
      isDataLoading: false
    }
  },

  computed: {
    headers() {
      return HEADERS
    }
  },

  methods: {
    ...mapMutations(['setBreadcrumbs']),

    loadData(filters) {
      this.isDataLoading = true

      this.$apiMethods.suppliers.balances({ page: this.page, ...filters })
        .then(({ items, pages }) => {
          if (!pages) {
            this.pages = 1
            this.rows = []
            return
          }

          this.setBreadcrumbs(items[0].name)

          this.summary = {
            ...items[0].summary,
            updated_at: items[0].summary.updated_at ?
              this.$moment(items[0].summary.updated_at).format('DD.MM.YYYY HH:mm') :
              ''
          }

          this.rows = (items[0].balances || []).map(item => ({
            ...item,
            updated_at: item.updated_at ? this.$moment(item.updated_at).format('DD.MM.YYYY HH:mm') : ''
          }))
        })
        .finally(() => { this.isDataLoading = false })
    },

    setBreadcrumbs(supplierName) {
      this.setBreadcrumbs([{
        text: 'Баланс поставщика' + (supplierName ? ` (${supplierName})` : ''),
        disabled: true
      }])
    },

    getCellClasses(item) {
      return [
        'balance-cell',
        item && item.warning && 'balance-cell_warning'
      ]
    },

    getFiltersQuery() {
      return this.filtersQuery
    }
  }
}
</script>

<style lang="scss" scoped>
.supplier-balances {
  height: 100%;

  .table-wrapper {
    height: 100%;
  }

  tr {
    td {
      height: 30px !important;
      font-size: 13px !important;
      padding: 1px 5px !important;
    }
  }

  .total-row {
    background: #ededed;
  }

  .balance-cell {
    &_warning {
      background: $data-table-row-attention-bg;
    }
  }
}
</style>
